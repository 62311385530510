/* custom styles */
.g-card {
  margin: 10px;
  width: 280px;
}


.g-card-image {
  border-radius: 10px;
  width: 280px;
  height: 350px;
  box-shadow: 0px 0px 3px 1px #ccc;
  /* height: auto;
  max-height: 480;
  width: auto;
  max-width: 300; */
}
.g-card-image:hover {
  cursor: pointer;
}

.g-card-info {
  margin-top: 10px;
  min-height: 100px;
}


.g-card-title {
  font-size: 24px;
  margin: 0px;
}


.g-card-sub-title {
  font-size: 16px;
  margin: 0px;
}


.success-msg {
  color: #57A773;
  padding: 10px 15px;
}


.err-msg {
  color: #EE6352;
  padding: 10px 15px;
}

.navbar-light .navbar-nav .nav-link .nav-custom {
  padding-left: unset !important;
}

a.nav-link.nav-custom.link {
  padding-left: unset !important;
  color: #555 !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  padding-right: 20px;
} 
a.nav-link.nav-custom.link:hover {
  color: #444 !important;
}
#navbar-toggle.navbar-collapse.collapse.show {
  padding-left: unset !important;
}
.link {
  display: block;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  color: #555;
}
.link--icon {
  position: relative;
  transition: transform 0.2s ease-in-out;
  color: #6C7581;
  overflow: hidden;
  z-index: 1;
}
.link--icon:hover {
  color: #fcfcfc;
  cursor: pointer;
}
.link--icon:focus {
  color: #fcfcfc;
  cursor: pointer;
}
.link--icon:before {
  content: '';
  display: inline;
  right: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  border-bottom:2px solid #555;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}
.link--icon:hover:before,
.link-icon:focus:before {
  right: 0;
}
.link--icon {
  padding: 0;
}

.link--icon:hover,
.link--icon:focus {
  color: #555;
}

.link--icon:active {
  transform: translateY(-5px);
}
.header-text {
  text-shadow: 1px 1px 1px  #929eaa;
  color:  rgb(54, 54, 54);
  padding-right: 5px !important;
}
.header-icon {
  margin-bottom: unset !important;

  font-family: 'Cinzel Decorative', cursive;
}
.contact-image {
  height: 50%;
}
.about .link--icon {
  margin-right: 0.8em;
}
.center {
  display: flex;
  align-items: center;
}
.btn {
  display: block;
  color:  #444 !important;
  text-align: left !important;
  cursor: pointer;
  padding: 1em;
  font-weight: 500;
  font-size: 1rem;
  transition: transform 0.2s ease-in-out;
}

.btn--outline {
  position: relative;
  overflow: hidden;
  border: 1px solid #555 !important;
  z-index: 1;
}

.btn:hover,
.btn:focus {
  color: #fcfcfc !important;
}

.btn--outline:before {
  content: '';
  position: absolute;
  background-color: #555;
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn--outline:hover:before,
.btn--outline:focus:before {
  right: 0;
}
.social li {
  font-family: 'Poppins', sans-serif;
  padding-right: 20px;
  font-size: 25px;
}
.made-with {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  color: #555;
}
.text-custom {
  font-family: 'Poppins', sans-serif;
}
.profilepic {
  border-radius: 50%;
  width: 200px;
  margin-bottom: 20px;
}
