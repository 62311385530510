* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4 {
  line-height: 1.2;
  color: #444;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.3rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none !important;
}


@media (max-width: 900px) {
  h2 {
    font-size: 2.6rem;
  }
}